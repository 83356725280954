/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


/***********************
* minor main asjustments 
************************/
html,
body
{
    overflow: hidden;
}
/* set the 3d Canvas behind the page content */
.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: 0;
}


/**********************
* Fonts
***********************/
@font-face {
    font-family: headerFont;
    src: url('../static/Fonts/DonGraffiti.otf');
}
@font-face {
    font-family: subHeaderFont;
    src: url('../static/Fonts/SedgwickAveDisplayRegular.ttf');
}
@font-face {
    font-family: textFont;
    src: url('../static/Fonts/SedgwickAveDisplayRegular.ttf');
}
h1{
    font-size: 4em;
}
h2{
    font-size: 2em;
}
p{
    font-size: 1.1em;
}
.headerTxt{
    font-family: headerFont;
    color: transparent;
    background-image: url('../static/img/gold.jpg');
    background-clip: text;
    -webkit-background-clip: text;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 20%;

}
.subHeaderTxt{
    font-family: subHeaderFont;
    color: palevioletred;
}
.contentTxt{
    font-family: textFont;
    color: whitesmoke;
}


/********************
* Loading Screen
********************/
.loadingScreen{
    z-index: 10000;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.progressContainer{
    position: relative;
    z-index: 10001;
    width: 500px;
    height: 500px;
}
.progressBar{
    position: absolute;
    background-image: url('../static/img/offthemuscle.svg');
    background-size: cover;
    background-repeat: no-repeat;
    z-index:30000;
    top: 0;
    left: 0;
    width: 500px;
    height: 0%;
}
.loadingScreen label{
    color: white;
    margin-top: 2rem;
    font-family: headerFont;
    font-size: 4rem;
}


/************
*Glassmorphism
*************/
.glass{
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.glassNav{
    /*background: rgba(0, 50, 116, 0.2);*/
    background: linear-gradient(90deg, rgba(193,193,193,0.15) 0%, rgba(193,193,193,0.45) 30%, rgba(193,193,193,0.45) 70%, rgba(193,193,193,0.15) 100%);
    border: 1px solid rgba(255, 255, 255, 0.15);
}


/*set the position and size of the branding contianer */
.branding{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width:500px;
    display: flex;
    flex-direction: row;
}
.branding img{
    height: 5em;
    width: auto;
}
.branding h2{
    display: flex;
    flex-direction: column-reverse;
    text-align: start;
    color: white;
    font-family: subHeaderFont;
}



/********************
* Pages Container
********************/
.pageContainer{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    outline: none;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    grid-template-areas: 
        "content content content content content content content content content content"
        "content content content content content content content content content content"
        "content content content content content content content content content content"
        "content content content content content content content content content content"
        "content content content content content content content content content content"
        "content content content content content content content content content content"
        "content content content content content content content content content content"
        "content content content content content content content content content content"
        "content content content content content content content content content content"
        "nav nav nav nav nav nav nav nav nav nav";
}

.pageContainer header{
    text-align: center;
    grid-column: 2 / 10;
    grid-row: 1 / 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

section .textContainer{
    grid-column: 2 / 6;
    grid-row: 4 / 10;
    padding: 1rem;
}





/********************
* Home Container
********************/
.homeContent{
    /*position: absolute;
    top: 0;
    left: 0;*/
    grid-area: content;
    display: grid;
    height: 90vh;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 9vh);
}

.homeImages{
    position: relative;
    grid-row: 4 / 10;
    grid-column: 6 / 10;
}
/*Polaroids*/
.polaroid{
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 300px;
    background-image: url('../static/img/polaroid640.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
}
#p1{
    top: -3rem;
    left: 6rem;
    rotate: 20deg;
}

#p2{
    top: 10rem;
    left: 14rem;
    rotate: -12deg;
}

#p3{
    top: 17rem;
    left: 6rem;
    rotate: -30deg;
}
.polaroidImg{
    z-index: 1;
    position: absolute;
    top: 5%;
    left: 8%;
    width:85%;
    height:60%;
}
.img1{
    background-image: url('../static/images/p1.jpg');
    background-size: cover;
}
.img2{
    background-image: url('../static/images/p2.jpg');
    background-size: cover;
}
.img3{
    background-image: url('../static/images/p3.jpg');
    background-size: cover;
}


/********************
* About Container
********************/
.aboutContent{
    grid-area: content;
    display: grid;
    height: 90vh;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 9vh);
}

.aboutImage{
    position: relative;
    grid-row: 4 / 10;
    grid-column: 6 / 10;
}
.aboutImage img{
    position: absolute;
    width: 40vh;
    height: auto;
    top: 3rem;
    left: 3rem;
}




/********************
* Events Container
********************/
.eventContent{
    grid-area: content;
    display: grid;
    height: 90vh;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 9vh);
}

.eventControls{
    grid-column: 1 / 11;
    grid-row: 3 / 11;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 7vh);
}

button{
    border: none;
    text-align: center;
}

.lftArr {
    background-color: transparent;
    background-image: url("../static/img/arrowLft.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    grid-column: 1 / 2;
    grid-row: 5;
}
.rgtArr {
    background-color: transparent;
    background-image: url("../static/img/arrowRgt.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    grid-column: 10 / 11;
    grid-row: 5;
}
.ticketButton{
    background-color: hotpink;
    grid-column: 5 / 7;
    grid-row: 10;
}

/********************
* Media Container
********************/
.mediaContent{
    grid-area: content;
    display: grid;
    height: 90vh;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 9vh);
}

.mediaControls{
    grid-column: 1 / 11;
    grid-row: 3 / 11;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 7vh);
}

button{
    border: none;
    text-align: center;
}

.lftArr {
    background-color: transparent;
    background-image: url("../static/img/arrowLft.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    grid-column: 1 / 2;
    grid-row: 5;
}
.rgtArr {
    background-color: transparent;
    background-image: url("../static/img/arrowRgt.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    grid-column: 10 / 11;
    grid-row: 5;
}

/********************
* Contact Container
********************/
.contactContent{
    grid-area: content;
    display: grid;
    height: 90vh;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 9vh);
}


/********************
* Nav
********************/
nav {
    /*background-color: rgba( 255, 255, 255, 0.5);*/
    z-index: 1000;
    font-family: headerFont;
    font-size: 2em;
    height: 100%;
    grid-area: nav;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

nav ul{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.navItem{
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    margin-right: 50px;
    height: 100%;
    width: 100px;
    color: transparent;
    justify-content: center;
    align-items: center;
    background-image: url('../static/img/gold.jpg');
    background-clip: text;
    -webkit-background-clip: text;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 30%;
}
#events{
    background: none;
    color: palevioletred;
}